<template>
  <v-text-field v-bind="$attrs" v-on="$listeners" hide-details />
</template>

<script>
export default {
  name: "TextFieldWrapper"
};
</script>

<style scoped></style>
