<template>
  <v-autocomplete v-bind="$attrs" v-on="$listeners" hide-details />
</template>

<script>
export default {
  name: "AutocompleteWrapper"
};
</script>

<style scoped></style>
